<template>
  <v-app-bar app dark color="#2c3e50">
    <v-app-bar-nav-icon
      @click="$emit('toggle-drawer')"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>
      Playoff Pick'em
    </v-toolbar-title>
    <v-spacer />
    <v-btn text v-if="!isLoggedIn" @click="accountLogIn">Log In</v-btn>
    <span v-if="isLoggedIn">{{loggedInUser.preferredDisplayName}}</span>
    <v-btn small text v-if="isLoggedIn" @click="clickLogout">(Log out)</v-btn>
  </v-app-bar>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  mounted() {
    const logInRoute = localStorage.getItem('log-in-route')
    localStorage.removeItem('log-in-route')
    if (logInRoute) {
      this.$router.replace(logInRoute)
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      loggedInUser: 'getLoggedInUser',
    }),
    baseUrl () {
      return process.env.VUE_APP_API_BASE_URL
    },
    googleOAuthLocation () {
      return `${this.baseUrl}/oauth2/authorization/google`
    },
  },
  methods: {
    ...mapActions({
      logout: 'logout',
    }),
    accountLogIn() {
      console.log(this.$route.path);

      const path = this.$route.path
      if ("/" === path) {
        localStorage.removeItem('log-in-route')
      } else {
        localStorage.setItem('log-in-route', path)
      }
      window.location = this.googleOAuthLocation
    },
    async clickLogout () {
      try {
        await this.logout();
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
